import {
    IImageProps,
    IStackTokens,
    Image,
    PrimaryButton,
    Stack,
    Text,
} from '@fluentui/react';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { linkStyle } from '../../../Common/util/CommonStyles';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { ThemeContext } from '../../models/ThemeContext';
import { routeKeys } from '../../../Common/util/RouteUtils';
import { useIsMobile } from '../../../Common/hooks/useIsMobile';

interface IHomeHeroSectionProps {}

export const HomeHeroSection: React.FC<IHomeHeroSectionProps> = (
    props: IHomeHeroSectionProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const sectionStackTokens: IStackTokens = { childrenGap: 10 };
    const isMobile = useIsMobile();

    const imageProps: Partial<IImageProps> = {
        src: process.env.PUBLIC_URL + '/abLogo_cropped.svg',
    };

    return (
        <Stack horizontalAlign="center" verticalAlign="center" horizontal>
            {!isMobile && (
                <Image
                    {...imageProps}
                    alt="Azure Bridge Hub"
                    width={200}
                    height={200}
                />
            )}

            <Stack
                tokens={sectionStackTokens}
                style={{
                    width: !isMobile ? '25%' : '100%',
                }}
            >
                <Text variant="xxLarge">Azure Bridge Hub</Text>
                {!isMobile && (
                    <Text variant="small">
                        Providing a comprehensive platform for tracking releases
                        and deployments. We strive to empower teams with the
                        tools they need to streamline their release processes,
                        increase visibility and collaboration, and ultimately
                        deliver high-quality software to their customers faster
                        and more efficiently.
                    </Text>
                )}
                <PrimaryButton
                    style={{
                        padding: '20px',
                    }}
                >
                    <Link
                        to={routeKeys.Features.Releases.Latest}
                        className={'link ' + linkStyle(theme)}
                    >
                        See All Releases
                    </Link>
                </PrimaryButton>
            </Stack>
        </Stack>
    );
};
