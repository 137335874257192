import React, { useContext } from 'react';
import { Text, FontWeights, Image, ImageFit } from '@fluentui/react';
import { ThemeContext } from '../../models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { useIsMobile } from '../../../Common/hooks/useIsMobile';

export const PageBrand: React.FC = () => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const isMobile = useIsMobile();

    return (
        <div>
            {isMobile ? (
                <div>
                    <img
                        src={process.env.PUBLIC_URL + '/abLogo_cropped.svg'}
                        alt={'Azure Bridge Logo'}
                        height="40px"
                        width="40px"
                        style={{
                            display: 'flex',
                        }}
                    />
                </div>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                        src={process.env.PUBLIC_URL + '/abLogo_cropped.svg'}
                        alt={'Azure Bridge Logo'}
                        width="66.67px"
                        height="40px"
                        style={{
                            paddingTop: '4px',
                        }}
                    />
                    <Text
                        variant="xLarge"
                        styles={{
                            root: {
                                fontWeight: FontWeights.semibold,
                                padding: '0 5px 0 10px',

                                // The below 3 lines are a hack to overcome figure 11 in this article: https://iamvdo.me/en/blog/css-font-metrics-line-height-and-vertical-align
                                margin: '-3px 0 3px 0',
                                position: 'relative',
                                bottom: -1,

                                color: theme.palette.themePrimary,
                            },
                        }}
                    >
                        Azure Bridge
                    </Text>
                    <Text
                        variant="xSmall"
                        styles={{
                            root: {
                                fontWeight: FontWeights.semibold,
                                color: theme.palette.themePrimary,
                                margin: '5px 0 0 0',
                            },
                        }}
                    >
                        {process.env.REACT_APP_BUILD_VERSION}
                    </Text>
                </div>
            )}
        </div>
    );
};
