import React, { useEffect, useState } from 'react';
import { PageContent } from '../../HubLayout/components/PageLayout/PageContent';
import { ReleaseImporter } from './Importer/ReleaseImporter';
import { IStackTokens, Stack, Text } from '@fluentui/react';
import { AdminComponent } from './AdminComponent';
import { BannerList } from './Banner/BannerList';
import {
    GlobalConfigProperties,
    getConfig,
} from '../../Common/api/ABHub/ABHubGlobalConfig';
import { HydrationEndpoints } from './HydrationEndpoints/HydrationEndpoints';

export const Administration: React.FC = () => {
    const stackTokens: IStackTokens = { childrenGap: 10 };

    const environment = getConfig(GlobalConfigProperties.Environment);
    const [validEnv, setValidEnv] = useState<boolean>(false);

    useEffect(() => {
        if (environment === 'Development' || environment === 'Test') {
            setValidEnv(true);
        }
    }, [environment]);

    return (
        <div className="Administration-root">
            <PageContent>
                <Text
                    variant="xLarge"
                    styles={{ root: { fontWeight: 'bold' } }}
                >
                    Administration
                </Text>
                <Stack
                    tokens={stackTokens}
                    styles={{
                        root: {
                            paddingTop: '15px',
                        },
                    }}
                >
                    {validEnv && (
                        <AdminComponent
                            title="Release Importer"
                            component={<ReleaseImporter />}
                        />
                    )}

                    <AdminComponent
                        title="Banner Tool"
                        component={<BannerList />}
                    />

                    <AdminComponent
                        title="Admin Endpoints"
                        component={<HydrationEndpoints />}
                    />
                </Stack>
            </PageContent>
        </div>
    );
};
