import { agpAxiosClient } from '../../Common/api/ABHub/ABHubTokenConfig';
import { AxiosResponse, CancelToken } from 'axios';
import {
    getConfig,
    GlobalConfigProperties,
} from '../../Common/api/ABHub/ABHubGlobalConfig';
import { Guid } from '../../Common/models/Guid';
import { Build, BuildSearchResult } from '../models/Build';
import { Release, ReleaseSearchResult } from '../models/Release';
import { Artifact } from '../models/Artifact';
import { Service } from '../models/Service';
import {
    AGCPPublishResponse,
    RequestResult,
} from '../../AGCP/models/AirGapMessage';
import { EgressDate } from '../../HubLayout/models/EgressDate';
import {
    CloudEnvironmentId,
    CloudEnvironmentResponse,
} from '../models/CloudEnvironmentId';
import { DeploymentMetadataItem } from '../models/Deployment';
import { BuildMetadataItem } from '../models/Build';
import { ReplicationStatusResult } from '../models/ReplicationStatusResult';
import { FileHash } from '../models/FileHash';

// ARTIFACTS------------------------------------------------------
export const getArtifactList = async (
    cancelToken?: CancelToken,
    artifactId?: string
): Promise<Artifact[]> => {
    let agssUrl =
        getConfig(GlobalConfigProperties.AgssUrl) +
        '/api/pipeline/artifact/search';
    if (artifactId) {
        agssUrl += `?SearchQuery=${artifactId}`;
    }
    const response = await agpAxiosClient.get<Artifact[]>(agssUrl, {
        cancelToken,
    });

    return response.data;
};

export const getArtifactDetails = async (
    artifactId: string,
    cancelToken?: CancelToken
): Promise<Artifact> => {
    const agssUrl = getConfig(GlobalConfigProperties.AgssUrl);
    const response = await agpAxiosClient.get<Artifact>(
        agssUrl + `/api/pipeline/artifact/${artifactId}`,
        { cancelToken }
    );

    return response.data;
};

export const getManifestDownload = async (
    artifactId: string,
    cancelToken?: CancelToken
): Promise<boolean> => {
    try {
        const agssUrl = getConfig(GlobalConfigProperties.AgssUrl);
        const response = await agpAxiosClient.get(
            agssUrl + `/api/artifact/${artifactId}/manifest`,
            { cancelToken, responseType: 'blob' }
        );

        const blob = new Blob([response.data], {
            type: 'application/octet-stream',
        });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);

        link.download = `${artifactId}-manifest.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);

        return true;
    } catch (error) {
        console.error('Error downloading manifest:', error);
        return false;
    }
};

export const activateArtifact = async (
    artifactId: string,
    cancelToken?: CancelToken
): Promise<RequestResult<boolean>> => {
    const agssUrl = getConfig(GlobalConfigProperties.AgssUrl);
    const response = await agpAxiosClient.post<RequestResult<boolean>>(
        agssUrl + `/api/pipeline/artifact/activate?artifactId=${artifactId}`,
        { cancelToken }
    );

    return response.data;
};

export const deactivateArtifact = async (
    artifactId: string,
    cancelToken?: CancelToken
): Promise<RequestResult<boolean>> => {
    const agssUrl = getConfig(GlobalConfigProperties.AgssUrl);
    const response = await agpAxiosClient.post<RequestResult<boolean>>(
        agssUrl + `/api/pipeline/artifact/deactivate?artifactId=${artifactId}`,
        { cancelToken }
    );

    return response.data;
};
//----------------------------------------------------------------------

// BUILDS---------------------------------------------------------------
export const getBuildInstanceList = async (
    cancelToken?: CancelToken,
    buildInstanceCorrelationId?: string
): Promise<BuildSearchResult[]> => {
    let agssUrl =
        getConfig(GlobalConfigProperties.AgssUrl) +
        `/api/pipeline/build/search/`;
    if (buildInstanceCorrelationId) {
        agssUrl += `?SearchQuery=${buildInstanceCorrelationId}`;
    }
    const response = await agpAxiosClient.get<BuildSearchResult[]>(agssUrl, {
        cancelToken,
    });

    return response.data;
};

export const getBuildInstanceDetails = async (
    buildInstanceCorrelationId: string,
    cancelToken?: CancelToken
): Promise<Build> => {
    let agssUrl = getConfig(GlobalConfigProperties.AgssUrl);
    agssUrl = agssUrl + `/api/pipeline/build/${buildInstanceCorrelationId}`;

    const response = await agpAxiosClient.get<Build>(agssUrl, { cancelToken });

    return response.data;
};
//-----------------------------------------------------------------------

// RELEASE DATA----------------------------------------------------------
export const getReleaseList = async (
    serviceTreeIds: string[],
    releaseCorrelationIds?: string[],
    environment?: string[],
    deploymentTypes?: string[],
    projectNames?: string[],
    searchTerms?: string,
    pageNumber?: number,
    cancelToken?: CancelToken
): Promise<ReleaseSearchResult[]> => {
    if (serviceTreeIds.length > 15) {
        // too many ids, GET will fail
        // set to [] so all releases will be returned
        serviceTreeIds = [];
    }

    var agssUrl =
        getConfig(GlobalConfigProperties.AgssUrl) +
        `/api/pipeline/release/search?serviceTreeIds=${serviceTreeIds}`;

    const useServiceTreeIdAsLookupValue = getConfig(
        GlobalConfigProperties.UseServiceTreeIdAsLookupValue
    );

    if (releaseCorrelationIds) {
        agssUrl += `&releaseCorrelationIds=${releaseCorrelationIds}`;
    }

    if (environment) {
        agssUrl += `&environment=${environment}`;
    }

    if (deploymentTypes) {
        agssUrl += `&deploymentTypes=${deploymentTypes}`;
    }

    if (projectNames) {
        agssUrl += `&projectNames=${projectNames}`;
    }

    if (searchTerms) {
        agssUrl += `&searchTerms=${searchTerms.trim()}`;
    }

    if (useServiceTreeIdAsLookupValue) {
        agssUrl += `&useServiceTreeIdAsLookupValue=${useServiceTreeIdAsLookupValue}`;
    }

    agssUrl += pageNumber
        ? `&PageNumber=${pageNumber}&PageSize=100`
        : `&PageNumber=1&PageSize=100`;

    const response = await agpAxiosClient.get<ReleaseSearchResult[]>(agssUrl, {
        cancelToken,
    });

    return response.data;
};

export const getReleaseDetails = async (
    releaseCorrelationId: Guid,
    cancelToken?: CancelToken
): Promise<Release> => {
    const agssUrl = getConfig(GlobalConfigProperties.AgssUrl);
    const response = await agpAxiosClient.get<Release>(
        agssUrl + `/api/pipeline/release/${releaseCorrelationId}`,
        { cancelToken }
    );

    // Deployment Metadata does not automatically convert to ES6 Map so it needs to convert it here
    if (response.data.stages.length > 0) {
        response.data.stages.forEach((stage) => {
            if (stage.deployments.length > 0) {
                stage.deployments.forEach((deployment) => {
                    deployment.deploymentMetadataItems = new Map<
                        string,
                        DeploymentMetadataItem
                    >(Object.entries(deployment.deploymentMetadataItems));
                });
            }
            if (stage.builds.length > 0) {
                stage.builds.forEach((build) => {
                    build.buildMetadataItems = new Map<
                        string,
                        BuildMetadataItem
                    >(Object.entries(build.buildMetadataItems));
                });
            }
        });
    }

    updateAzDeployerDeploymentTimeline(response.data);

    return response.data;
};

export const getReleaseExport = async (
    releaseCorrelationIds: Guid[],
    cancelToken?: CancelToken
): Promise<boolean> => {
    const agssUrl = getConfig(GlobalConfigProperties.AgssUrl);

    // Use axios.post for batch export with releaseCorrelationIds in the request body
    const response: AxiosResponse<Blob> = await agpAxiosClient.post(
        `${agssUrl}/api/pipeline/release/export`,
        releaseCorrelationIds,
        { responseType: 'blob', cancelToken }
    );

    // Create file link in the browser's memory
    const href = URL.createObjectURL(response.data);

    // Create "a" HTML element with href to the file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'release_data_batch.json');
    document.body.appendChild(link);
    link.click();

    // Clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    return true;
};

export const postReleaseImport = async (
    releaseEntities: File,
    cancelToken?: CancelToken
): Promise<RequestResult<string> | undefined> => {
    if (releaseEntities) {
        const agssUrl = getConfig(GlobalConfigProperties.AgssUrl);
        // Make a POST request to the upload endpoint with the formData
        const formData = new FormData();
        formData.append('request', releaseEntities);

        var response = await agpAxiosClient.post(
            `${agssUrl}/api/pipeline/release/import`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                cancelToken,
            }
        );

        return response.data;
    }

    return undefined;
};
//----------------------------------------------------------------------------

// LOOKUPS----------------------------------------------------------
export const getServicesList = async (
    cancelToken?: CancelToken
): Promise<Service[]> => {
    const agssUrl = getConfig(GlobalConfigProperties.AgssUrl);
    const useServiceTreeIdAsLookupValue = getConfig(
        GlobalConfigProperties.UseServiceTreeIdAsLookupValue
    );

    const response = await agpAxiosClient.get<Service[]>(
        agssUrl +
            `/api/pipeline/lookup/services?useServiceTreeIdAsLookupValue=${useServiceTreeIdAsLookupValue}`,
        { cancelToken }
    );
    return response.data;
};

export const getCloudsList = async (
    cancelToken?: CancelToken
): Promise<CloudEnvironmentId[]> => {
    const agssUrl = getConfig(GlobalConfigProperties.AgssUrl);

    const response = await agpAxiosClient.get<CloudEnvironmentResponse>(
        agssUrl + `/api/environment`,
        { cancelToken }
    );

    return response.data.data;
};

export const getDeploymentTypesList = async (
    cancelToken?: CancelToken
): Promise<string> => {
    const agssUrl = getConfig(GlobalConfigProperties.AgssUrl);

    const response = await agpAxiosClient.get(
        agssUrl + `/api/deploymentTypes`,
        { cancelToken }
    );

    return response.data;
};

export const getProjectsList = async (
    cancelToken?: CancelToken
): Promise<Service[]> => {
    const agssUrl = getConfig(GlobalConfigProperties.AgssUrl);
    const response = await agpAxiosClient.get<Service[]>(
        agssUrl + `/api/pipeline/lookup/projects`,
        { cancelToken }
    );
    return response.data;
};
//----------------------------------------------------------------------------

// REPLICATE BUILD----------------------------------------------------------
export const replicateBuild = async (
    artifactId: string
): Promise<AGCPPublishResponse<boolean>> => {
    var agssUrl = getConfig(GlobalConfigProperties.AgssUrl);
    const response = await agpAxiosClient.post(
        agssUrl + `/api/replication/${artifactId}`
    );
    return response.data;
};
//----------------------------------------------------------------------------

// GET LATEST EGRESS DATES-----------------------------------------------------
export const getLatestEgressDates = async (
    cancelToken?: CancelToken
): Promise<EgressDate[]> => {
    var agssUrl = getConfig(GlobalConfigProperties.AgssUrl);
    const response = await agpAxiosClient.get<EgressDate[]>(
        agssUrl + `/api/latestEgress`,
        { cancelToken }
    );
    return response.data;
};
//-----------------------------------------------------------------------------

// FILE HASH -----------------------------------------------------
export const resetFileHash = async (
    hash: string,
    environmentName: string,
    cancelToken?: CancelToken
): Promise<RequestResult<FileHash>> => {
    const agssUrl = getConfig(GlobalConfigProperties.AgssUrl);

    const response = await agpAxiosClient.put<RequestResult<FileHash>>(
        `${agssUrl}/api/files/reset`,
        {}, // No body data to send for this request
        {
            params: {
                hash,
                environmentName,
            },
            cancelToken: cancelToken,
        }
    );

    return response.data;
};

//----------------------------------------------------------------------------

// HELPER METHODS-------------------------------------------------------------
const updateAzDeployerDeploymentTimeline = (response: Release) => {
    if (response.stages) {
        response.stages.forEach((stage) => {
            if (stage.deployments) {
                stage.deployments.forEach((deployment) => {
                    if (deployment.deploymentType === 'AzDeployer') {
                        if (deployment.currentStatus) {
                            deployment.currentStatus = mapToAzDStatus(
                                deployment.currentStatus
                            ) as ReplicationStatusResult;
                        }

                        if (deployment.currentReplication) {
                            if (deployment.currentReplication.statusList) {
                                const updatedStatusList =
                                    deployment.currentReplication.statusList.map(
                                        (status) => {
                                            return mapToAzDStatus(status);
                                        }
                                    ) as ReplicationStatusResult[];

                                deployment.currentReplication.statusList =
                                    updatedStatusList;
                            }

                            if (deployment.currentReplication.currentStatus) {
                                deployment.currentReplication.currentStatus =
                                    mapToAzDStatus(
                                        deployment.currentReplication
                                            .currentStatus
                                    ) as ReplicationStatusResult;
                            }
                        }

                        if (deployment.replications) {
                            deployment.replications.forEach((replication) => {
                                if (replication.statusList) {
                                    const updatedStatusList =
                                        replication.statusList.map((status) => {
                                            return mapToAzDStatus(status);
                                        }) as ReplicationStatusResult[];

                                    replication.statusList = updatedStatusList;
                                }

                                if (replication.currentStatus) {
                                    replication.currentStatus = mapToAzDStatus(
                                        replication.currentStatus
                                    ) as ReplicationStatusResult;
                                }
                            });
                        }
                    }
                });
            }
        });
    }
};

const mapToAzDStatus = (status: ReplicationStatusResult) => {
    switch (status.name) {
        case 'Sent to CDS':
            return {
                ...status,
                name: 'Sent to FileCloud',
            } as ReplicationStatusResult;
        case 'Deployment Arrived':
            return {
                ...status,
                name: 'Pending',
            } as ReplicationStatusResult;
        case 'Submitted to Deployer':
            return {
                ...status,
                name: 'Active',
            } as ReplicationStatusResult;
        case 'Deployed':
            return {
                ...status,
                name: 'Completed',
            } as ReplicationStatusResult;
    }
};
//-----------------------------------------------------------------------------
