import {
    IContextualMenuItem,
    IContextualMenuProps,
    DirectionalHint,
    CompoundButton,
    Icon,
} from '@fluentui/react';
import React, { useContext } from 'react';
import { ThemeContext } from '../../models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';

interface IEllipsisProps {
    menuItems: IContextualMenuItem[];
}

export const Ellipsis: React.FC<IEllipsisProps> = (props: IEllipsisProps) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        directionalHint: DirectionalHint.bottomRightEdge,
        directionalHintFixed: true,
        items: props.menuItems,
    };

    const contextButtonStyles = {
        menuIcon: {
            display: 'none',
        },
        root: {
            paddingTop: '0px',
            paddingRight: '5px',
            paddingBottom: '0px',
            paddingLeft: '5px',
            maxHeight: '40px',
            minHeight: '40px',
            border: 'none',
            backgroundColor: theme.palette.neutralLighter,
        },
    };

    return (
        <div className="Ellipsis-root">
            <CompoundButton menuProps={menuProps} styles={contextButtonStyles}>
                <Icon iconName="MoreVertical" />
            </CompoundButton>
        </div>
    );
};
