import {
    Stack,
    Pivot,
    PivotItem,
    IconButton,
    IIconProps,
    FontWeights,
} from '@fluentui/react';
import React, { useContext } from 'react';
import { UserProfile } from '../../UserPages/UserProfile';
import { routeKeys } from '../../../../Common/util/RouteUtils';
import { PageBrand } from '../PageBrand';
import { useHistory, useLocation } from 'react-router-dom';
import { ThemeContext } from '../../../models/ThemeContext';
import { getThemeFromString } from '../../../../Common/util/localStorageUtils';

interface IMobileSitHeaderProps {
    openNav: () => void;
}

export const MobileSiteHeader: React.FC<IMobileSitHeaderProps> = (
    props: IMobileSitHeaderProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const history = useHistory();
    const location = useLocation();

    const mailIcon: IIconProps = {
        iconName: 'NewMail',
        styles: { root: { fontWeight: FontWeights.bold } },
    };

    let homeItem = (
        <PivotItem
            headerText="Azure Bridge"
            itemKey={routeKeys.Home}
            onRenderItemLink={() => {
                return <PageBrand />;
            }}
        />
    );

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <div
                style={{
                    marginRight: 'auto',
                }}
            >
                <Stack
                    horizontal
                    style={{
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        style={{
                            color: theme.palette.neutralPrimary,
                        }}
                        iconProps={{
                            iconName: 'GlobalNavButton',
                        }}
                        onClick={() => {
                            props.openNav();
                        }}
                    />
                    <Pivot
                        headersOnly={true}
                        selectedKey={null}
                        onLinkClick={(
                            item?: PivotItem,
                            ev?: React.MouseEvent<HTMLElement, MouseEvent>
                        ) => {
                            const itemKey = item
                                ? item.props.itemKey || ''
                                : '';
                            if (location.pathname !== itemKey) {
                                history.push(itemKey);
                            }
                        }}
                    >
                        {homeItem}
                    </Pivot>
                </Stack>
            </div>

            <div
                style={{
                    margin: '2px 3px 0 2px',
                    paddingTop: '5px',
                }}
            >
                <IconButton
                    iconProps={mailIcon}
                    title='For help, click here to email the "Azure Bridge Web Team" distribution list.'
                    href="mailto:azure-bridge-web@microsoft.com"
                    ariaLabel="Help"
                />
            </div>

            <div
                style={{
                    margin: '2px 3px 0 2px',
                    paddingTop: '5px',
                }}
            >
                <IconButton
                    iconProps={{ iconName: 'Documentation' }}
                    title="For information on the Azure Bridge Hub, click here."
                    href="https://eng.ms/docs/cloud-ai-platform/azure-edge-platform-aep/aep-silver/sovereign-platform-team/sovereign-release-manager/team-docs/srportal/overview"
                    target="_blank"
                    ariaLabel="Help"
                />
            </div>

            <div
                style={{
                    marginTop: '2px',
                    marginRight: '5px',
                }}
            >
                <UserProfile />
            </div>
        </div>
    );
};
