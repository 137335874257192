import { IColumn } from '@fluentui/react';

export const ReleaseColumns: IColumn[] = [
    {
        key: 'releaseId',
        name: 'Release',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        fieldName: 'releaseName',
    },
    {
        key: 'service',
        name: 'Service',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        fieldName: 'serviceName',
    },
    {
        key: 'completionIndicator',
        name: 'Status',
        minWidth: 120,
        maxWidth: 120,
        isResizable: true,
        fieldName: 'completionIndicatorString',
    },
    {
        key: 'componnentName',
        name: 'Component Name',
        minWidth: 130,
        maxWidth: 130,
        isResizable: true,
        fieldName: 'componentName',
    },
    {
        key: 'environments',
        name: 'Environment(s)',
        minWidth: 130,
        maxWidth: 130,
        isResizable: true,
        fieldName: 'environments',
    },
    {
        key: 'deploymentTypes',
        name: 'Deployment Type(s)',
        minWidth: 130,
        maxWidth: 130,
        isResizable: true,
        fieldName: 'deploymentTypes',
    },
    {
        key: 'project',
        name: 'Project',
        minWidth: 160,
        maxWidth: 160,
        isResizable: true,
        fieldName: 'projectName',
    },
    {
        key: 'createdOn',
        name: 'Created On',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        fieldName: 'createdOn',
    },
    {
        key: 'updatedOn',
        name: 'Last Updated',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        fieldName: 'updatedOn',
    },
];
